<template>
    <div class="wiki">
        <div class="header">
            <div class="logo">
                <img alt="Vue logo" src="@/assets/logo-hide.png" />
            </div>
            <div class="title">
                &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;世泊云开放WIKI - 错误故障解决信息
            </div>
        </div>

        <!-- <el-row :gutter="20">

            <el-row :gutter="20" style="width: 89%;margin-left: 6%;margin-top: 50px;margin-bottom: 30px;">

                <span style="font-size: 36px;font-weight: 600;">错误码: {{ data.abnormal_info.error_code_snapshot }}</span>
                <br>
                <span style="font-size: 24px;font-weight: 400;line-height: 50px;">错误原因: {{ data.abnormal_info.error_info }}</span>

            </el-row>

        </el-row> -->

        <div class="errortitle">

            <div class="content">

                <el-row :gutter="20">

                    <span style="font-size: 36px;font-weight: 600;">错误码: {{ data.abnormal_info.error_code_snapshot }}</span>
                    <br>
                    <span style="font-size: 24px;font-weight: 400;line-height: 50px;">错误原因: {{ data.abnormal_info.error_info }}</span>

                </el-row>

            </div>

        </div>


        <el-row :gutter="20" style="width: 90%;margin-left: 5%;">
            <el-col :span="18">
                <el-descriptions class="margin-top" title="错误排查及解决方法" :column="1" direction="vertical" border></el-descriptions>
                <el-card class="box-card" shadow="hover" v-for="data in data.abnormal_wiki" :key="data.id">
                    <div slot="header" class="clearfix">
                        <span>可能的错误: {{ data.possible_question }}</span>
                    </div>
                    <div>
                        {{ data.resolvent }}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">

                <el-descriptions class="margin-top" title="错误详细信息" :column="1" direction="vertical" border >
                    <el-descriptions-item label="错误码">{{ data.abnormal_info.error_code_snapshot }}</el-descriptions-item>
                    <el-descriptions-item label="错误信息">{{ data.abnormal_info.error_info }}</el-descriptions-item>
                    <el-descriptions-item label="错误等级">{{ data.abnormal_info.error_level }}</el-descriptions-item>
                </el-descriptions>
                <br>
                <el-descriptions class="margin-top" title="错误发生模块" :column="1" direction="vertical" border>
                    <el-descriptions-item label="模块名">{{ data.abnormal_module.module_name }}</el-descriptions-item>
                </el-descriptions>
                <br>
                <el-descriptions class="margin-top" title="异常信息归属" :column="1" direction="vertical" border>
                    <el-descriptions-item label="软件系统">{{ data.product_library.product_name }}</el-descriptions-item>
                    <el-descriptions-item label="错误系统类型">{{ data.product_library.product_type }}</el-descriptions-item>
                </el-descriptions>

            </el-col>
        </el-row>

    </div>
</template>

<script>
// @ is an alias to /src
import { get } from "@/api/wiki/abnormal";
export default {
    name: 'wiki_abnormal',
    components: {
    },
    data() {
        return {
            errorInfo: {},
            data: {}
        }
    },
    created() {
        this.errorInfo = this.$route.query
        this.getdata(this.errorInfo)
    },
    methods: {
        getdata(errorInfo) {
            this.$http(
                get(errorInfo
                ), (res) => {
                    console.log(res);
                    this.data = res.data
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    height: 80px;
    border-bottom: 3px solid #0055b6;
    display: flow-root;

    .logo {
        // line-height: 25px;
        padding: 26px;
        float: left;
        padding-right: 0px;
    }

    .title {
        line-height: 76px;
        font-size: 18px;
        font-weight: 600;
    }
}

.errortitle{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #f4f4f4;
    border-bottom: 1px solid #b6d8ff;
    .content{
        width: 88%;
        margin: 0 auto;
    }
}

.box-card{
    margin-bottom: 30px;
}
</style>